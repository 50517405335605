import React, { useEffect, useState, useRef } from "react";
import { isBrowser, isMobile, browserName, fullBrowserVersion, mobileModel, osVersion, osName } from "react-device-detect";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { CardMedia } from "@mui/material";
import { HttpClientApi } from "../../../../../service/client-rest-api";
import { deviceInfo } from "../../../../../interface/MainInterface";
import { CommonUtils } from "../../../../../service/common_utils";
import LoadingCircleOnVideo from "../../../../../utilities/LoadingCircleOnVideo";

import "./ClientIVSPlayer.css";

interface propsType {
  endLoadInfo: boolean;
  broad_seq: string;
  playbackUrl: string;
  vod_start_sec: number;
  vod_end_sec: number;
  videoStatusCallback: any;
  videoPositionCallback: any;
  hidden_menu: boolean;
  muted: boolean;
  controlOpen: boolean;
}

export let player: any = null;
const clientApi = new HttpClientApi();

const _deviceInfo: deviceInfo = {
  isBrowser: isBrowser,
  isMobile: isMobile,
  browserName: browserName,
  fullBrowserVersion: fullBrowserVersion,
  mobileModel: mobileModel,
  osName: osName,
  osVersion: osVersion,
};

export function VodIVSPlayerHigh(props: propsType) {
  const videoRef: any = useRef();
  const PLAYBACK_URL = props.playbackUrl;
  const cUtils = new CommonUtils();
  const videoControls = React.createRef<HTMLDivElement>();
  const [videoDuration, setVideoDuration] = useState(100000);
  const [videoCurrentTime, setVideoCurrentTime] = useState(props.vod_start_sec);
  const [videoRemianTime, setVideoRemainTime] = useState("00:00:00");
  const isChange = useRef(false);
  const [customControl, setCustomControl] = useState(false);
  const [play, setPlay] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [onStateReady, setStateOnReady] = useState(false);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setVideoCurrentTime(props.vod_start_sec);
      setVideoRemainTime("00:00:00");
      setCustomControl(false);
      setPlay(true);
      setIsLoading(false);
    };
  }, []);

  useEffect(() => {
    if (PLAYBACK_URL !== "") {
      mediaPlayerScriptLoaded(PLAYBACK_URL);
    }
  }, [PLAYBACK_URL]);

  const putErrorLog = async (errorMsg: string) => {
    try {
      const param = {
        command: "put_broaderror_data",
        broad_seq: props.broad_seq,
        device_info: _deviceInfo,
        error_msg: errorMsg,
      };

      await clientApi.put_broaderror_data(param);
    } catch (error) {
      console.error(error);
    }
  };

  let PlayerState: any;
  const mediaPlayerScriptLoaded = (_PLAYBACK_URL: string) => {
    const MediaPlayerPackage = window.IVSPlayer;

    if (!MediaPlayerPackage.isPlayerSupported) {
      console.error("지원하지 않는 브라우저입니다. 다른 브라우저로 시도하시거나. 더현대닷컴 APP을 설치하시기 바랍니다.");
      props.videoStatusCallback("unSupported");
      putErrorLog("UnSupported Browser");
    }

    PlayerState = MediaPlayerPackage.PlayerState;
    const PlayerEventType = MediaPlayerPackage.PlayerEventType;

    // Initialize player
    player = MediaPlayerPackage.create({
      serviceWorker: {
        url: "amazon-ivs-service-worker-loader.js",
      },
    });
    player.attachHTMLVideoElement(document.getElementById("videoPlayer"));
    player.addEventListener(PlayerState.READY, onReady);
    player.addEventListener(PlayerState.IDLE, onIdle);
    player.addEventListener(PlayerState.PLAYING, onStatePlaying);
    player.addEventListener(PlayerState.ENDED, onStateEnd);
    player.addEventListener(PlayerEventType.ERROR, onError);
    player.addEventListener(PlayerEventType.TIME_UPDATE, onTimeUpdate);
    player.addEventListener(PlayerEventType.SEEK_COMPLETED, onSeekCompleted);

    player.setAutoQualityMode(true);
    player.setAutoplay(false);
    player.setMuted(true);
    player.setVolume(1.0);

    player.load(_PLAYBACK_URL);

    const videoWorks = !!document.createElement("video").canPlayType;
    if (videoWorks) {
      videoRef.current.controls = false;
      videoControls.current?.classList.remove("hidden");
      setCustomControl(true);
    }
  };

  useEffect(() => {
    if (props.hidden_menu) {
      videoControls.current?.classList.add("padding-10");
      videoControls.current?.classList.remove("bottom--11");
    } else {
      videoControls.current?.classList.remove("padding-10");
      videoControls.current?.classList.add("bottom--11");
    }
  }, [props.hidden_menu]);

  const onIdle = (cue: any) => {
    setStateOnReady(false);
    const newState = player.getState();
    console.info(`Player State - ${newState}`);
    props.videoStatusCallback("onIdle");
    // setTimeout(() => { //vod 종료시 다시 처음부터 시작
    //   player.seekTo(props.vod_start_sec);
    //   player.play();
    // }, 3000);
  };

  const onReady = (cue: any) => {
    setStateOnReady(true);
    const newState = player.getState();
    console.info(`Player State - ${newState}`);
    props.videoStatusCallback("onReady");
    player.seekTo(props.vod_start_sec);
    player.setAutoplay(true);
    player.play();
  };

  const onStatePlaying = (cue: any) => {
    setStateOnReady(false);
    const newState = player.getState();
    console.info(`Player State - ${newState} : ${player.getDuration()}`);
    props.videoStatusCallback("onStatePlaying");
    if (props.vod_end_sec > 0 && props.vod_end_sec < player.getDuration()) {
      setVideoDuration(props.vod_end_sec);
    } else {
      setVideoDuration(player.getDuration());
    }

    let realPostion = player.getPosition();
    if (props.vod_end_sec > 0 && realPostion >= props.vod_end_sec) {
      // player.seekTo(props.vod_start_sec);
    }
    setIsLoading(false);
    isChange.current = false;
  };

  const onStateEnd = (cue: any) => {
    setStateOnReady(false);
    const newState = player.getState();
    console.info(`Player State - ${newState}`);
    setPlay(false);
    props.videoStatusCallback("onStateEnd");
  };

  const onError = (err: any) => {
    setStateOnReady(false);
    console.warn("Player Event - ERROR:", err);
    setPlay(false);
    props.videoStatusCallback("onError");
    putErrorLog(JSON.stringify(err));
  };

  const onTimeUpdate = (cue: any) => {
    let realPostion = player.getPosition();
    let realDuration = player.getDuration();

    if (props.vod_end_sec > 0 && props.vod_end_sec < realDuration) {
      // VOD 종료시간이 Custom이면 해당 값으로 Duration 변경
      realDuration = props.vod_end_sec;
    }
    props.videoPositionCallback("onTimeUpdate", realPostion);

    if (!isChange.current) {
      setVideoCurrentTime(realPostion);
      const remainTime = realDuration - realPostion;
      const t = cUtils.convertSecToTime(remainTime);
      if (remainTime < 0) {
        // 남은시간이 -로 남는경우(vod_end_sec는 정수(00초)인데 실제 스트림은 xx.235초 같이 소수가 있을때)
        setVideoRemainTime("00:00:00");
      } else {
        setVideoRemainTime(t.hours + ":" + t.minutes + ":" + t.seconds);
      }
    }
    if (props.vod_end_sec > 0 && realPostion >= props.vod_end_sec) {
      // vod_end_sec는 수기 값으로 customcontrol은 max지만 스트림은 계속 재생함. 따라서 해당 시간 이상은 영상을 일시정지 시킴.
      player.pause();
      // setPlay(false);
    }
  };

  const onSeekCompleted = (cue: any) => {
    setIsLoading(false);
  };

  const togglePlay = () => {
    if (player.isPaused()) {
      player.play();
      // setPlay(true);
    } else {
      player.pause();
      // setPlay(false);
    }
  };

  useEffect(() => {
    if (player !== null) {
      if (player.isPaused()) {
        setPlay(false);
      } else {
        setPlay(true);
      }
    }
  }, [player?.isPaused()]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    isChange.current = true;
    setVideoCurrentTime(newValue as number);
  };

  const completeChange = (event: any, newValue: number | number[]) => {
    setIsLoading(true);
    player.seekTo(newValue as number);
    isChange.current = false;
  };

  function valueLabelFormat(value: number) {
    const t = cUtils.convertSecToTime(value - props.vod_start_sec);
    return t.hours + ":" + t.minutes + ":" + t.seconds;
  }

  return (
    <>
      <Box
        id="vod-screen"
        component="div"
        className="vod-screen-overlay"
        sx={customControl ? { display: "none" } : { display: "flex" }}
      ></Box>
      {onStateReady && (
        <div className="video-poster">
          <CardMedia component="img" image={"/images/ready_img.png"} sx={{ width: "100%", height: "100%", zIndex: "903" }} />
        </div>
      )}
      <video
        id="videoPlayer"
        ref={videoRef}
        className="th-client-video"
        poster={"/images/ready_img.png"}
        playsInline
        controls
        muted
        autoPlay
        preload="none"
      ></video>
      <div className="th-video-controls padding-10 hidden" id="video-controls" ref={videoControls}>
        <Stack direction="row" spacing={1}>
          <IconButton
            sx={props.hidden_menu && props.controlOpen ? { padding: 0, display: "flex" } : { padding: 0, display: "none" }}
            onClick={togglePlay}
          >
            {play ? <PauseIcon className="color-snow" /> : <PlayArrowIcon className="color-snow" />}
          </IconButton>
          <Slider
            aria-label="video"
            value={videoCurrentTime}
            min={props.vod_start_sec}
            max={videoDuration}
            step={0.1}
            valueLabelDisplay="auto"
            valueLabelFormat={valueLabelFormat}
            onChange={handleChange}
            onChangeCommitted={completeChange}
            sx={
              props.controlOpen
                ? props.hidden_menu
                  ? { pt: "11px", ["@media (pointer: coarse)"]: { pt: "11px" } }
                  : { pt: "11px", ["@media (pointer: coarse)"]: { pt: "11px" }, "& .MuiSlider-thumb": { width: "0px" } }
                : { display: "none" }
            }
          />

          <Typography
            sx={
              props.hidden_menu && props.controlOpen
                ? { color: "snow", display: "inline-block", ["@media (pointer: coarse)"]: { pt: "6px" } }
                : { color: "snow", display: "none", ["@media (pointer: coarse)"]: { pt: "6px" } }
            }
          >
            <span className="svc-text">{videoRemianTime}</span>
          </Typography>
        </Stack>
      </div>
      <LoadingCircleOnVideo loading={isLoading} />
    </>
  );
}
