import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Amplify from "aws-amplify";
import awsExports from "./config/aws-exports";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import MainContainer from "./components/MainContainer";
import { appConfig, setUserArn } from "./config/Config";
import { userState } from "./interface/MainInterface";
import * as AuthMain from "./service/auth/AuthMain";
import { HttpClientApi } from "./service/client-rest-api";
Amplify.configure(awsExports);

const adminList = [
  "bluegury@hyundai-ite.com",
  "heocube@naver.com",
  "rkskfl20@hyundai-ite.com",
  "min.ch9095@hyundai-ite.com",
];

let userParam: userState = {
  id: "",
  nickName: "",
  picture: "",
  isAuth: false,
  isAdmin: false,
  isSuperAdmin: false,
  userArn: "",
};

const theme = createTheme({
  typography: {
    fontFamily: "NanumBarunGothic, Arial",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'NanumBarunGothic';
          font-style: normal;
          font-display: swap;
          font-weight: 1;
          src: local('NanumBarunGothic'), local('NanumBarunGothic-Regular'), url('./fonts/NanumBarunGothic.woff') format('woff');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
  palette: {
    secondary: {
      main: "#e90909",
    },
  },
});

const App: React.FC = () => {
  const ClientApi = new HttpClientApi();
  const [userState, setUserState] = useState<userState>();
  const [webViewStatus, setWebViewStatus] = useState("web");

  useEffect(() => {
    var broswerInfo = window.navigator.userAgent;
    if (broswerInfo.indexOf("Web_Android") > -1) {
      setWebViewStatus("android");
    } else if (broswerInfo.indexOf("AppStore") > -1) {
      setWebViewStatus("ios");
    }
    setUserInfo();
  }, []);

  async function setUserInfo() {
    const result = await AuthMain.checkAuthenticated();
    if (result) {
      setChimeUserArn();
      userParam.isAuth = true;
      const userAttr = await AuthMain.getUserAttr();
      userParam.id = userAttr.email;
      userParam.nickName = userAttr.chat_name;
      userParam.picture = userAttr.picture;
      if (adminList.includes(userAttr.email)) {
        userParam.isSuperAdmin = true;
      }

      const res = await AuthMain.checkAdmin();
      if (res) {
        userParam.isAdmin = true;
      }
    }
    setUserState(userParam);
  }

  async function setChimeUserArn() {
    if (appConfig.appInstanceUserArn === "") {
      const res = await ClientApi.get_chime_user_arn();
      if (res.result_code !== "400") {
        userParam.userArn = res.result_body.app_instance_user_arn;
        setUserArn(res.result_body.app_instance_user_arn);
      } else {
        const newArn = await ClientApi.create_chime_user();
        if (newArn.result_code !== "400") {
          userParam.userArn = newArn.result_body.AppInstanceUserArn;
          setUserArn(newArn.result_body.AppInstanceUserArn);
        }
      }
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        {userState !== undefined ? (
          <BrowserRouter>
            <MainContainer userState={userState} webViewStatus={webViewStatus} />
          </BrowserRouter>
        ) : (
          <></>
        )}
      </div>
    </ThemeProvider>
  );
};

export default App;
