import { useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

import LiveHome from "./page/LiveHome";
import LiveTheHyundai from "./page/TheHyundai/LivePlayer/LiveTheHyundai";
import LiveTheHyundaiAndroid from "./page/TheHyundai/LivePlayer/LiveTheHyundaiAndroid";
import LiveTheHyundaiIOS from "./page/TheHyundai/LivePlayer/LiveTheHyundaiIOS";
import LiveTheHyundaiFrame from "./page/TheHyundai/LivePlayer/LiveTheHyundaiFrame";
import TheHyundaiShare from "./page/Share/TheHyundaiShare";
import BroadcastDownload from "./page/Download/BroadcastDownload";
import BroadcastGuide from "./page/Guide/BroadcastGuide";

import Box from "@mui/material/Box";

import { userState } from "../interface/MainInterface";

interface propsType {
  userState: userState;
  webViewStatus: string;
}

const MainContainer = (props: propsType) => {
  let navigate = useNavigate();

  useEffect(() => {
    const pathName: string[] = window.location.pathname.split("/");
    if (pathName.length > 2) {
      if (pathName[1] === "live" || pathName[1] === "stream") {
        document.body.style.overscrollBehaviorY = "none";
        document.body.style.height = "100%";
        document.body.style.minHeight = "100%";
      } else {
        document.body.style.overscrollBehaviorY = "auto";
        document.body.style.height = "100%";
        document.body.style.minHeight = "100%";
      }
    } else {
      document.body.style.overscrollBehaviorY = "auto";
      document.body.style.height = "100%";
      document.body.style.minHeight = "100%";
    }

    window.scrollTo(0, 0);
  }, [window.location]);

  return (
    <Box id="MainContainer" sx={{ flex: 1, paddingY: 0, paddingX: 0, backgroundColor: "transparent" }}>
      <Routes>
        <Route path="/" element={<LiveHome />} />
        <Route path="/frame/:broadseq" element={<LiveTheHyundaiFrame userState={props.userState} />} />
        <Route path="/stream/:broadseq" element={<LiveTheHyundai userState={props.userState} webViewStatus={props.webViewStatus} />} />
        <Route path="/androidFrame/:broadseq" element={<LiveTheHyundaiAndroid userState={props.userState} webViewStatus={props.webViewStatus} />} />
        <Route path="/iOSFrame/:broadseq" element={<LiveTheHyundaiIOS userState={props.userState} webViewStatus={props.webViewStatus} />} />
        <Route path="/TheHyundaiShare" element={<TheHyundaiShare />} />
        <Route path="/broadcastDownload" element={<BroadcastDownload />} />
        <Route path="/broadcastGuide" element={<BroadcastGuide />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </Box>
  );
};

export default MainContainer;
