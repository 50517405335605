import Box from "@mui/material/Box";

import "./LiveShare.css";

interface propsType {
  broad_seq: string;
  iconSize: string;
  postMsg: any;
  hidden_menu: boolean;
  isNotFloat: any;
  isFloat: any;
}

const LiveShare = (props: propsType) => {

  const handleShareModalOpen = () => {
    // 더현대로 소셜공유 모달 요청
    props.postMsg({ msg: "shareModal" }, "*");
  };

  return (
    <>
      <Box sx={props.hidden_menu ? { display: "none" } : { display: "flex", justifyContent: "center" }} onClick={handleShareModalOpen}>
        <img className="thd-footer-right-icon" src="/images/btn-share.png" alt="" />
      </Box>
    </>
  );
};

export default LiveShare;
