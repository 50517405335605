import { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import { getRandomNum } from "../../../../../utilities/utils";
import TheHyundaiEmoji from "../../../../../utilities/TheHyundai/TheHyundaiEmoji";

interface propsType {
  like_click: any;
  new_like_count: number;
  hidden_menu: boolean;
  iconSize: string;
}

const LiveProduct = (props: propsType) => {
  const [thdw, setThdw] = useState([] as any);
  const [thdb, setThdb] = useState([] as any);
  const [hp, setHp] = useState([] as any);
  const [hw, setHw] = useState([] as any);
  const [cy, setCy] = useState([] as any);
  const [cp, setCp] = useState([] as any);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setThdw([] as any);
      setThdb([] as any);
      setHp([] as any);
      setHw([] as any);
      setCy([] as any);
      setCp([] as any);
    };
  }, []);

  const btnLikeClickCallback = () => {
    props.like_click();
    like_up();
  };

  const arryMax: number = 4;
  const like_up = () => {
    const max = 1;
    const target = getRandomNum(0, 6);
    const makefunc = (arr: any, setArr: any) => {
      for (let i = 0; i < max; i++) {
        if (arr.length < arryMax) {
          setArr((ele: any) => [
            ...ele,
            {
              id: Date.now() + "/" + i,
            },
          ]);
        }
      }
    };
    if (target === 1) {
      makefunc(thdw, setThdw);
    } else if (target === 2) {
      makefunc(thdb, setThdb);
    } else if (target === 3) {
      makefunc(hp, setHp);
    } else if (target === 4) {
      makefunc(hw, setHw);
    } else if (target === 5) {
      makefunc(cy, setCy);
    } else {
      makefunc(cp, setCp);
    }
  };

  useEffect(() => {
    if (props.new_like_count > 0) like_up();
  }, [props.new_like_count]);

  return (
    <>
      <Box sx={props.hidden_menu ? { display: "none" } : { display: "flex", justifyContent: "center" }}>
        <Stack direction="column" spacing={0}>
          <div className="heart_root">
            <div id="divheartActions" className="heart-actions no-scroll">
              <div className="like-wrapper">
                {thdw.map((item: any, idx: any) => (
                  <TheHyundaiEmoji key={item.id} emoji={thdw} setEmoji={setThdw} emojiCategory="thehd-wht" />
                ))}
                {thdb.map((item: any, idx: any) => (
                  <TheHyundaiEmoji key={item.id} emoji={thdb} setEmoji={setThdb} emojiCategory="thehd-blk" />
                ))}
                {hp.map((item: any, idx: any) => (
                  <TheHyundaiEmoji key={item.id} emoji={hp} setEmoji={setHp} emojiCategory="heart-pk" />
                ))}
                {hw.map((item: any, idx: any) => (
                  <TheHyundaiEmoji key={item.id} emoji={hw} setEmoji={setHw} emojiCategory="heart-wht" />
                ))}
                {cy.map((item: any, idx: any) => (
                  <TheHyundaiEmoji key={item.id} emoji={cy} setEmoji={setCy} emojiCategory="candy-yg" />
                ))}
                {cp.map((item: any, idx: any) => (
                  <TheHyundaiEmoji key={item.id} emoji={cp} setEmoji={setCp} emojiCategory="candy-pp" />
                ))}
              </div>
            </div>
          </div>
          <Box sx={{ display: "flex" }} onClick={btnLikeClickCallback}>
            <img className="thd-footer-right-icon thd-footer-right-icon-heart" src="/images/btn-like.png" />
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default LiveProduct;
