import Box from "@mui/material/Box";
import { Button, CardMedia, Stack } from "@mui/material";

const LiveHome = () => {
  return (
    <>
      <Box sx={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "#000000" }}>
        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Stack direction={"column"} spacing={4} sx={{ alignItems: "center" }}>
            <CardMedia component={"img"} image="/images/logo.png" sx={{ width: "50%" }}></CardMedia>
            <Button
              variant="text"
              onClick={() => {
                window.location.href = "https://thd-admin.hd-smartstore.com/";
              }}>
              {"관리자 페이지 바로가기"}
            </Button>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default LiveHome;
