import { useEffect, useState, forwardRef, useImperativeHandle } from "react";

// MUI
import Box from "@mui/material/Box";
import { Grid, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

// Interface
import { HttpGameApi } from "../../../../../service/game-rest-api";
import { useInterval } from "../../../../../utilities/UseInterval";
import { CommonUtils } from "../../../../../service/common_utils";

// Local CSS
import "./LiveGame.css";

interface propsType {
  gameInfo: any;
  userId: string;
  custNo: string;
  lastNo: string;
  applyClick: any;
  goLoginPage: any;
}

const gameApi = new HttpGameApi();

const LiveGame = (props: propsType, ref: any) => {
  const cUtils = new CommonUtils();
  const [btnText, setBtnText] = useState("");
  const [checkFinish, setCheckFinish] = useState(false);
  const [alreadyJoin, setAlreadyJoin] = useState(false);
  const [applyNum, setApplyNum] = useState(0);
  const [checkTimeout, setCheckTimeout] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setBtnText("응모하기");
      setCheckFinish(false);
      setAlreadyJoin(false);
      setApplyNum(0);
      setCheckTimeout(false);
      setIsTimeout(false);
    };
  }, []);

  // 이미 게임에 참여했는지 확인
  const checkGameState = async () => {
    const param: any = {
      game_info: props.gameInfo,
      user_id: props.userId,
    };

    const res = await gameApi.check_game(param);
    if (res.response.result === "exist") setAlreadyJoin(true);
    setCheckFinish(true);
  };

  const applyGame = async () => {
    if (alreadyJoin) return;
    if (props.userId === "") {
      props.goLoginPage();
      return;
    }
    const param: any = {
      game_info: props.gameInfo,
      user_id: props.userId,
      cust_no: props.custNo,
      last_no: props.lastNo !== "undefined" && props.lastNo !== undefined && props.lastNo !== null ? props.lastNo : "",
      apply_info: {},
    };

    const res = await gameApi.apply_game(param);
    if (res.code === "200") {
      if (res.response.result === "success") {
        props.applyClick();
        checkGameState();
      }
    }
  };

  const getGameApplyNum = async (seq: string) => {
    const param: any = {
      game_seq: seq,
    };

    const res = await gameApi.get_game_apply_list(param);
    if (res.code === "200") {
      const apply_num = res.response.apply_list.length;
      setApplyNum(apply_num);
    } else {
      setApplyNum(0);
      console.error(res.response.error_msg);
    }
  };

  const applyNumCallback = () => {
    setApplyNum((applyCount: number) => applyCount + 1);
  };

  useImperativeHandle(ref, () => ({
    applyNumCallback,
  }));

  useEffect(() => {
    checkGameState();
    getGameApplyNum(props.gameInfo.sk);
  }, []);

  useEffect(() => {
    if (alreadyJoin) {
      setCheckTimeout(false);
      setIsTimeout(false);
      setBtnText("응모완료");
    } else {
      if (checkTimeout) {
        if (isTimeout) {
          setBtnText("응모종료");
        } else {
          setBtnText("응모하기");
        }
      } else {
        setBtnText("응모하기");
      }
    }
  }, [alreadyJoin, checkTimeout]);

  useEffect(() => {
    let timeOut = "";
    if (props.gameInfo.event_condition?.timeout_dtm !== undefined) {
      timeOut = props.gameInfo.event_condition?.timeout_dtm;
    } else {
      timeOut = props.gameInfo.timeout;
    }
    if (timeOut !== "") {
      setCheckTimeout(true);
      const remainSec = cUtils.calRemainSec(timeOut);
      if (remainSec >= 0) {
        setBtnText("응모하기2\n" + remainSec + "초 남음");
      } else {
        setCheckTimeout(false);
        setIsTimeout(true);
        setBtnText("응모종료");
      }
    } else {
      setCheckTimeout(false);
      setIsTimeout(false);
    }
  }, [props.gameInfo]);

  // 게임 종료시간 확인
  const delay: number = 1000; // 1초마다 변경
  useInterval(
    () => {
      let timeOut = "";
      if (props.gameInfo.event_condition?.timeout_dtm !== undefined) {
        timeOut = props.gameInfo.event_condition?.timeout_dtm;
      } else {
        timeOut = props.gameInfo.timeout;
      }
      const remainSec = cUtils.calRemainSec(timeOut);
      if (remainSec >= 0) {
        setIsTimeout(false);
        setBtnText("응모하기\n" + remainSec + "초 남음");
      } else {
        setCheckTimeout(false);
        setIsTimeout(true);
        setBtnText("응모종료");
      }
    },
    checkTimeout ? delay : null
  );

  return (
    <>
      <div className={`thd-live-game-wrapper`}>
        {checkFinish && props.gameInfo.flag === "apply" ? (
          <Box className="thd-live-game-layout">
            <Box className="thd-live-game-root">
              <Box component="img" className="thd-live-game-img" src="/images/game-box.png" />
              <Box className="thd-live-game-item">
                <Grid item xs={2} className="thd-live-game-item-grid">
                  <Stack spacing={0} direction="column">
                    <Stack spacing={0.5} direction="row" className="thd-live-game-stack-row">
                      <Typography
                        className="thd-live-game-text"
                        fontFamily="Noto Sans KR"
                        fontWeight="700"
                        fontSize="15px"
                        fontStyle="normal"
                        color="#111"
                        text-overflow="ellipsis"
                        overflow="hidden"
                      >
                        추첨하기
                      </Typography>
                      <Box sx={{ backgroundColor: "#FF376D", borderRadius: "8px" }} className="thd-live-game-draw-box">
                        <Typography
                          className="thd-live-game-text"
                          fontFamily="Noto Sans KR"
                          fontWeight="700"
                          fontSize="12px"
                          fontStyle="normal"
                          color="#FFFFFF"
                          text-overflow="ellipsis"
                          overflow="hidden"
                        >
                          {props.gameInfo.draw_number ? props.gameInfo.draw_number : props.gameInfo.event_condition?.draw_number}명
                        </Typography>
                      </Box>
                    </Stack>
                    <Typography
                      className="thd-live-game-text"
                      fontFamily="Noto Sans KR"
                      fontWeight="400"
                      fontSize="11px"
                      fontStyle="normal"
                      color="rgba(17, 17, 17, 0.80)"
                      text-overflow="ellipsis"
                      overflow="hidden"
                    >
                      {applyNum}명 참여 중
                    </Typography>
                  </Stack>
                </Grid>
                {alreadyJoin ? (
                  <Button
                    className="thd-live-game-button"
                    sx={{
                      color: "#fff",
                      border: "1px solid #fff",
                      borderRadius: "6px",
                      height: "40px",
                      marginLeft: "3px",
                      overflow: "hidden",
                      "&:hover": { border: "1px solid #fff" },
                    }}
                    variant="outlined"
                    onClick={() => {
                      applyGame();
                    }}
                  >
                    <Typography
                      className="thd-live-game-text"
                      fontFamily="Noto Sans KR"
                      fontWeight="700"
                      fontSize="11px"
                      fontStyle="normal"
                    >
                      {btnText}
                    </Typography>
                  </Button>
                ) : isTimeout ? (
                  <Button
                    className="thd-live-game-button"
                    sx={{
                      color: "#fff",
                      border: "1px solid #fff",
                      borderRadius: "6px",
                      height: "40px",
                      marginLeft: "3px",
                      overflow: "hidden",
                      "&:hover": { border: "1px solid #fff" },
                    }}
                    variant="outlined"
                  >
                    <Typography
                      className="thd-live-game-text"
                      fontFamily="Noto Sans KR"
                      fontWeight="700"
                      fontSize="11px"
                      fontStyle="normal"
                    >
                      {btnText}
                    </Typography>
                  </Button>
                ) : (
                  <Button
                    className="thd-live-game-button"
                    sx={{
                      background: "#fff",
                      color: "#FF376D",
                      border: "1px solid #fff",
                      borderRadius: "6px",
                      height: "40px",
                      marginLeft: "3px",
                      "&:hover": {
                        backgroundColor: "#fff",
                        border: "1px solid #fff",
                      },
                    }}
                    variant="contained"
                    onClick={() => {
                      applyGame();
                    }}
                  >
                    <Typography
                      className="thd-live-game-text"
                      fontFamily="Noto Sans KR"
                      fontWeight="700"
                      fontSize="11px"
                      fontStyle="normal"
                      whiteSpace={"pre-line"}
                    >
                      {btnText}
                    </Typography>
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default forwardRef(LiveGame);
