import "./BroadStop.css";

interface propsType {
  broad_info: any;
}

const BroadStopIOS = (props: propsType) => {
  return (
    <div>
      <div className="remain-item snow">
        <div className="remain-live-mark">
          <img src="/images/comingsoon.png" alt="" />
        </div>
        <div className="remain-time">방송이 종료되었습니다.</div>
      </div>
    </div>
  );
};

export default BroadStopIOS;
