import "./BroadStop.css";

interface propsType {
  broad_info: any;
}

const BroadStop = (props: propsType) => {
  return (
    <div>
      {/* Android에서 http 이미지 안보이는 이슈 대응 */}
      <video
        id="videoPlayer"
        className="th-client-video"
        poster={
          props.broad_info.broad_cover_url !== ""
            ? props.broad_info.broad_cover_url.startsWith("http://")
              ? "https://" + props.broad_info.broad_cover_url.substring(7)
              : props.broad_info.broad_cover_url
            : props.broad_info.setting.ready_img_url
        }
      ></video>
      <div className="remain-item snow">
        <div className="remain-live-mark">
          <img src="/images/comingsoon.png" alt="" />
        </div>
        <div className="remain-time">방송이 종료되었습니다.</div>
      </div>
    </div>
  );
};

export default BroadStop;
