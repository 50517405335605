import { useEffect, useState, forwardRef, useImperativeHandle } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import LiveLike from "../like/LiveLike";
import LiveShare from "../share/LiveShare";
import LiveProduct from "../product/LiveProduct";
import LiveChatBtn from "../chat/LiveChatBtn";

import { userState } from "../../../../../interface/MainInterface";

import "./LiveFooter.css";

interface propsType {
  broad_seq: string;
  broad_status: string;
  user_id: string;
  userState: userState;
  like_click: any;
  hidden_menu: boolean;
  sendMessage: any;
  prodList: any;
  postMsg: any;
  isNotFloat: any;
  isFloat: any;
  newQnAMsg: any;
  openChatInput: any;
  videoMode: number;
  screenEnv: string;
  videoStatus: string;
  rehearsal: boolean;
}

const LiveFooter = (props: propsType, ref: any) => {
  const iconSize: string = "44px";
  const [newLikeCount, setNewLikeCount] = useState(0);

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setNewLikeCount(0);
    };
  }, []);

  const addNewLiveCount = () => {
    setNewLikeCount((newLikeCount: number) => newLikeCount + 1);
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    addNewLiveCount,
  }));

  return (
    <>
      <Box component="div" sx={{ width: "100%" }} className="color-snow">
        <Stack
          direction="row"
          spacing={0}
          sx={{
            width: "100%",
            alignItems: "flex-end",
            justifyContent: "flex-start",
          }}
        >
          <LiveProduct
            isNotFloat={props.isNotFloat}
            isFloat={props.isFloat}
            hidden_menu={props.hidden_menu}
            prodList={props.prodList}
            postMsg={props.postMsg}
            broadSeq={props.broad_seq}
            userId={props.user_id}
            videoMode={props.videoMode}
          />
          <Stack
            direction="column"
            sx={{ padding: "16px 8px", position: "absolute", right: "3%" }}>
            <LiveLike like_click={props.like_click} new_like_count={newLikeCount} hidden_menu={props.hidden_menu} iconSize={iconSize} />
            {props.screenEnv === "mo" && (
              <LiveShare
                isNotFloat={props.isNotFloat}
                isFloat={props.isFloat}
                hidden_menu={props.hidden_menu}
                broad_seq={props.broad_seq}
                postMsg={props.postMsg}
                iconSize={iconSize}
              />
            )}
            {props.rehearsal || (props.broad_status === "START" && props.videoStatus === "onStatePlaying") ? (
              <LiveChatBtn
                broad_status={props.broad_status}
                user_id={props.user_id}
                hidden_menu={props.hidden_menu}
                sendMessage={props.sendMessage}
                iconSize={iconSize}
                openChatInput={props.openChatInput}
              />
            ) : (
              <></>
            )}
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default forwardRef(LiveFooter);
