import { useEffect, useState, useCallback, useRef } from "react";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { HttpClientApi, SetProdClickParams } from "../../../../../service/client-rest-api";
import "./LiveProduct.css";

interface propsType {
  hidden_menu: boolean;
  prodList: any;
  postMsg: any;
  isNotFloat: any;
  isFloat: any;
  broadSeq: any;
  userId: any;
  videoMode: number;
}

const LiveProduct = (props: propsType) => {
  const ClientApi = new HttpClientApi();
  const prodCircle = useRef<HTMLDivElement>(null);

  const [openProd, setOpenProd] = useState(false);
  const [prodList, setProdList] = useState([] as any);
  const [prodRender, setProdRender] = useState([] as any);
  const [prodPhotoRender, setProdPhotoRender] = useState([] as any);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // 상품 로드
    LoadProdList();
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setOpenProd(false);
      setProdList([] as any);
      setProdRender([] as any);
      setProdPhotoRender([] as any);
      setCurrentIndex(0);
    };
  }, []);

  useEffect(() => {
    if (props.prodList.length > 0) setProdList(props.prodList);
  }, [props.prodList]);

  // 상품 정보 불러오기
  const LoadProdList = () => {
    props.postMsg({ msg: "LoadProdList" }, "*");
  };

  // 상품 원 모양 버튼 이미지 슬라이드 애니메이션 함수
  useEffect(() => {
    const interval = setInterval(() => {
      if (!prodCircle.current) {
        return;
      }
      prodCircle.current.style.transition = `all 3s ease-in-out`;
      prodCircle.current.style.transform = `translateY(-${currentIndex}00%)`;
      if (currentIndex === prodList.length) {
        setTimeout(() => {
          if (!prodCircle.current) {
            return;
          }
          prodCircle.current.style.transition = `0s`;
          prodCircle.current.style.transform = `translateY(0)`;
        }, 3001);
        setCurrentIndex(1);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    }, 6000);

    return () => {
      clearInterval(interval);
    };
  }, [currentIndex, prodList]);

  // 상품 원 모양 버튼 이미지 슬라이드 HTML Element 설정
  const handleProdCicle = useCallback(() => {
    if (prodList.length > 0) {
      setProdPhotoRender(
        //props.broad_prod_list
        prodList
          .map((obj: any, index: number) => {
            return (
              <div className="slideItem" key={"key" + index} onClick={() => clickProd(obj)}>
                <img className="thd-item-thumb" src={obj.thumbImageUrl} alt="" />
                <div className="thd-item-desc">
                  <div className="thd-item-title">{obj.name}</div>
                  <div className="thd-item-price">{obj.zaitmDlvYn === "Y" && obj.salePrice === 0 ? "상담 후 결정" : numberWithCommas(obj.salePrice) + "원"}</div>
                </div>
              </div>
            );
          })
          .concat(
            <div className="slideItem" key={"key_" + prodList[0].productId} onClick={() => clickProd(prodList[0])}>
              <img className="thd-item-thumb" src={prodList[0].thumbImageUrl} alt="" />
              <div className="thd-item-desc">
                <div className="thd-item-title">{prodList[0].name}</div>
                <div className="thd-item-price">{prodList[0].zaitmDlvYn === "Y" && prodList[0].salePrice === 0 ? "상담 후 결정" : numberWithCommas(prodList[0].salePrice) + "원"}</div>
              </div>
            </div>
          )
      );
    }
  }, [prodList]);

  useEffect(() => {
    // 상품 사진 슬라이드
    handleProdCicle();
  }, [handleProdCicle]);

  const handleProdDrawerClose = () => {
    setOpenProd(false);
    props.isFloat();
  };
  const handleProdDrawerOpen = () => {
    setProdRender(
      prodList.map((obj: any, index: number) => {
        return (
          <div key={`prod${index}`}>
            <Box
              className="thd-prod-item-box"
              onClick={() => clickProd(obj)} // 품절이어도 상품 클릭은 됨
            >
              <Box className="thd-prod-item-info" sx={{ filter: obj.soldout ? "opacity(0.5)" : "none" }}>
                <Box className="thd-prod-image-box">
                  <img src={obj.thumbImageUrl} alt="" />
                </Box>
                <Box className="thd-prod-desc-box">
                  <div className="thd-prod-desc-title">{obj.name}</div>
                  <div className="thd-prod-desc-price">{obj.soldout ? "품절" : (obj.zaitmDlvYn === "Y" && obj.salePrice === 0 ? "상담 후 결정" : numberWithCommas(obj.salePrice) + "원")}</div>
                </Box>
              </Box>
              <Divider light />
            </Box>
          </div>
        );
      })
    );
    setOpenProd(true);
  };

  const handleProdOpen = () => {
    // 품절 상태 확인하기 위해 다시 상품 리스트 호출
    LoadProdList();
    // floating 막기
    props.isNotFloat();
    // Drawer 오픈 함수
    handleProdDrawerOpen();
  };

  const clickProd = async (prod: any) => {
    handleProdDrawerClose();
    try {
      let param: SetProdClickParams = {
        broad_seq: props.broadSeq,
        user_id: props.userId,
        prod_id: prod.productId,
        prod_name: prod.name,
        prod_price: prod.salePrice,
      };

      await ClientApi.set_prod_click(param).then(() => {});
    } catch (e) {
      console.error("set_prod_click ERROR : ", e);
    }
    setTimeout(() => {
      props.postMsg({ msg: "showProdDetail", detailUrl: prod.detailUrl }, "*");
    }, 200);
  };

  // Util
  const numberWithCommas = (x: any) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <>
      <Box
        sx={
          props.hidden_menu
            ? { display: "none" }
            : prodList.length === 0
            ? { display: "none" }
            : { display: "flex", justifyContent: "space-between" }
        }
        className="thd-thd-prod-circle-button-box white-shadow"
      >
        <Box className="thd-prod-circle-button ">
          <div className="thd-slider-circle" ref={prodCircle}>
            {prodPhotoRender}
          </div>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }} onClick={handleProdOpen}>
          <Box sx={{ border: "0.3px solid #DDDDDD", height: "45px" }}></Box>
          <Box className="thd-prod-circle-cnt">
            <span>{prodList.length}</span>
            <span style={{ fontSize: "11px" }}>더보기</span>
          </Box>
        </Box>
      </Box>
      <Drawer
        PaperProps={
          props.videoMode === 1
            ? {
                elevation: 0,
                style: {
                  borderTopRightRadius: "20px",
                  borderTopLeftRadius: "20px",
                },
                sx: {
                  ...{
                    width: "100%",
                    maxWidth: "56.25vh",
                    left: "50%",
                    transform: "translateX(calc(-50% + 0.5px)) !important",
                    overflow: "hidden",
                  },
                },
              }
            : {
                elevation: 0,
                style: {
                  borderTopRightRadius: "20px",
                  borderTopLeftRadius: "20px",
                },
              }
        }
        anchor="bottom"
        open={openProd}
        onClose={handleProdDrawerClose}
        className="thd-live-more-body"
      >
        <Box className="thd-live-more-body-box">
          <Box className="thd-prod-list-header">
            <Box className="thd-prod-list-header-title">
              <img src="/images/modal-shoplist.png" style={{ width: "20px", marginRight: "7px", marginTop: "2px" }} alt="" /> 판매상품{" "}
              <span>{prodList.length}</span>
            </Box>
            <IconButton sx={{ padding: 0 }} onClick={handleProdDrawerClose}>
              <CloseIcon sx={{ fontSize: "30px", color: "#BBBBBB" }} />
            </IconButton>
          </Box>
          <Box sx={{ width: "100%" }} className={props.videoMode === 1 ? "thd-prod-scroll" : ""}>
            <nav aria-label="Set Resolution">
              <div className="thd-prod-list">{prodRender}</div>
            </nav>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default LiveProduct;
