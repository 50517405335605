import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import dayjs from "dayjs";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

import { ListChildComponentProps, VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import { CommonUtils } from "../../../../../service/common_utils";
import { HttpChatApi } from "../../../../../service/chat-rest-api";
import { HttpClientApi } from "../../../../../service/client-rest-api";
import { broadSetting, userState } from "../../../../../interface/MainInterface";
import { appConfig } from "../../../../../config/Config";
import { Persistence } from "./ChatConstant";

import "./Chat.css";

interface propsType {
  broad_seq: string;
  channelArn: string;
  adminArn: string;
  chatUrl: string;
  chat_open: boolean;
  screenMode: string;
  hidden_menu: boolean;
  chatInitCallback: any;
  player_id: string;
  streamEventCallback: any;
  videoPosition: string;
  vod_started_at: string;
  userState: userState;
  setting: broadSetting;
  isNotFloat: any;
  isFloat: any;
}

interface autoLinkProp {
  text: String;
}

const chatApi = new HttpChatApi();

const ChatVerVod = (props: propsType, ref: any) => {
  const ClientApi = new HttpClientApi();
  const cUtils = new CommonUtils();

  const chatTextSize: string = "13px";

  const chatInnerRef = useRef<HTMLDivElement>();
  const listRef = useRef<any>(null); // Ref for chatting list
  const rowHeights = useRef<any>({}); // Array for set dynamic height foreach message contents

  const [messages, setMessages] = useState<any>([]);
  const [totalMessages, setTotalMessages] = useState<any>([]);
  const [needScrollEnd, setNeedScrollEnd] = useState<Boolean>(true);
  const [btnNewMsgBoxStyle, setBtnNewMsgBoxStyle] = useState({ display: "none" });
  const [visibleStartIndex, setVisibleStartIndex] = useState(0);
  const [visibility, setVisibility] = useState("visible");

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setMessages([]);
      setTotalMessages([]);
      setNeedScrollEnd(true);
      setBtnNewMsgBoxStyle({ display: "none" });
      setVisibleStartIndex(0);
      setVisibility("visible");
    };
  }, []);

  useEffect(() => {
    if (props.hidden_menu && !props.chat_open) {
      setVisibility((visibility) => "hidden");
      setBtnNewMsgBoxStyle({ display: "none" });
    } else {
      setVisibility((visibility) => "visible");
    }
  }, [props.hidden_menu, props.chat_open]);

  useEffect(() => {
    if (props.channelArn !== "") {
      getListChannelMessages(props.channelArn);
    }
  }, [props.channelArn]);

  // 최초 채널 입장시 기존 차임 메시지 조회(현재 최대 50건)
  const getListChannelMessages = async (channel_arn: string) => {
    const param: any = {
      channel_arn: channel_arn,
    };
    await chatApi.list_chat_history(param).then((result: any) => {
      if (result.code === "200") {
        setTotalMessages(result.response.chat_history.Messages);
        props.chatInitCallback(true);
      }
    });
  };

  useEffect(() => {
    // useEffect의 특성상 1초에 한번씩만 업데이트 됨. 나이스~
    let newMessages: any = [];
    let newManageMessages: any = [];
    for (let msg of totalMessages) {
      if (msg.CreatedTimestamp < props.vod_started_at) continue;
      if (msg.CreatedTimestamp > props.videoPosition) break;
      if (msg.Persistence === Persistence.NON_PERSISTENT && JSON.parse(msg.Content).prot === "view") continue;
      if (msg.Persistence === Persistence.NON_PERSISTENT) {
        const tmp_ts = dayjs(props.videoPosition).add(-1, "s").format("YYYYMMDDHHmmss");
        if (msg.CreatedTimestamp < tmp_ts) continue;
        newManageMessages = [...newManageMessages, msg];
        continue;
      }
      newMessages = [...newMessages, msg];
    }
    setMessages(newMessages);
  }, [props.videoPosition]);

  // 채팅창 ListItem의 높이를 동적 처리하기 위한 기능
  function getRowHeight(index: number) {
    return rowHeights.current[index] + 8 || 36;
  }

  // 각 row별 높이를 저장하기 위한 기능
  function setRowHeight(index: any, size: any) {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  // 채팅 메시지별 Row 그리는 기능(동적 높이 구현을 위한 기능 포함)
  function RenderRow(listProps: ListChildComponentProps) {
    const { index, style } = listProps;
    const newStyle = {
      left: "0px",
      height: style.height,
      width: "100%",
    };

    const rowRef = useRef<any>({});

    useEffect(() => {
      if (rowRef.current) {
        setRowHeight(index, rowRef.current.clientHeight);
      }
    }, [rowRef]);
    let textShadow = "";
    if (props.setting?.chat_shadow) textShadow = "1px 1px 1px " + props.setting.chat_shadow_color;

    return (
      <ListItem className="pd-0-imp" style={visibleStartIndex > 0 ? style : newStyle} key={index}>
        <ListItemText
          primary={
            <Typography ref={rowRef} component="div" className="chat-list-body">
              <Box
                sx={
                  messages[index].Sender.Arn === props.adminArn
                    ? {
                        fontSize: chatTextSize,
                        fontWeight: "500", //"bold",
                        color: "Snow",
                        display: "inline-flex",
                        flexDirection: "row",
                        borderRadius: 2,
                        backgroundColor: "#00000030", //"transparent",
                        pl: 1,
                        pr: 1,
                        pt: 1,
                        pb: 1,
                      }
                    : {
                        fontSize: chatTextSize,
                        fontWeight: "500", //"bold",
                        color: "Snow",
                        display: "inline-flex",
                        flexDirection: "row",
                        borderRadius: 2,
                      }
                }
              >
                <div>
                  <span
                    style={
                      messages[index].Sender.Arn === props.adminArn
                        ? {
                            color: "#F2FF5A", // 어드민, 노랑색
                            whiteSpace: "nowrap",
                            marginRight: "5px",
                            fontWeight: "bold",
                          }
                        : messages[index].Sender.Arn === props.userState.userArn
                        ? {
                            color: "#80C9FF", // 본인, 파랑색
                            whiteSpace: "nowrap",
                            marginRight: "5px",
                            fontWeight: "bold",
                          }
                        : {
                            color: "#ebeaea", // 일반 회원(어드민 제외), 회색
                            whiteSpace: "nowrap",
                            marginRight: "5px",
                            opacity: "0.8",
                            fontWeight: "bold",
                          }
                    }
                  >
                    {messages[index].Sender.Arn === props.adminArn
                      ? messages[index].Sender.Name
                      : cUtils.maskingChar(messages[index].Sender.Name)}
                  </span>
                  {messages[index].Content.split(appConfig.quote_separator).map((line: string, idx: number) => {
                    const repleLength = messages[index].Content.split(appConfig.quote_separator).length;
                    let styleCss: any = {
                      color: props.setting?.chat_text_color,
                      wordBreak: "break-all",
                      whiteSpace: "pre-line",
                    };
                    if (idx !== repleLength - 1) styleCss.color = "#ebeaea";
                    if (idx === 0 && messages[index].Sender.Arn === props.adminArn) {
                      return (
                        <span key={index} style={styleCss}>
                          <AutoLink text={line} />
                        </span>
                      );
                    } else if (idx === 0) {
                      return (
                        <span key={index} style={styleCss}>
                          {line}
                        </span>
                      );
                    } else {
                      return (
                        <span key={index + "-" + idx} style={styleCss}>
                          <br />
                          {appConfig.quote_separator + " "}
                          <AutoLink text={line} />
                        </span>
                      );
                    }
                  })}
                </div>
              </Box>
            </Typography>
          }
        />
      </ListItem>
    );
  }

  // 스크롤에 따라서 최신 메시지, 스크롤 자동 하단 등 처리를 위한 이벤트
  const evtItemRendered = (e: any) => {
    if (chatInnerRef.current) chatInnerRef.current.style.marginTop = "auto";
    setVisibleStartIndex((visibleStartIndex) => e?.visibleStartIndex);

    if (e?.visibleStopIndex > 0 && messages.length > 0) {
      if (e.visibleStopIndex >= messages.length - 1) {
        setNeedScrollEnd(true);
        setBtnNewMsgBoxStyle({ display: "none" });
      } else {
        setNeedScrollEnd(false);
      }
    }
  };

  // 채팅창 스크롤 제일 아래로 이벤트 처리
  useEffect(() => {
    if (messages.length > 0) {
      if (needScrollEnd) {
        setBtnNewMsgBoxStyle({ display: "none" });
        scrollToBottom();
      } else {
        if (!props.hidden_menu) setBtnNewMsgBoxStyle({ display: "inline" });
      }
    }
  }, [messages, needScrollEnd]);

  // 채팅창 내용 업데이트시 스크롤을 제일 아래로
  const scrollToBottom = () => {
    if (messages.length > 0) {
      listRef?.current.scrollToItem(messages.length, "end");
    }
  };
  const AutoLink = (prop: autoLinkProp) => {
    const delimiter = /(https?:\/\/[^\s]+)/g;
    return (
      <>
        {prop.text.split(delimiter).map((word, index) => {
          const match = word.match(delimiter);
          if (match) {
            const url = match[0];
            return (
              <a
                key={`a-t-k-${index}`}
                style={{ textDecoration: "none", color: "#FF376D " }}
                target="_blank"
                href={url.startsWith("http") ? url : `http://${url}`}
              >
                {url}
              </a>
            );
          }
          return word;
        })}
      </>
    );
  };

  // 부모 Component에서 접근 가능하도록 함수 전달.
  useImperativeHandle(ref, () => ({
    scrollToBottom,
  }));

  return (
    <>
      <Box
        id="thd-chat-root"
        component="div"
        sx={
          /* 메인화면에서 비디오영역 클릭시 메뉴 감추기 */
          {
            height: "30vh",
            visibility: visibility,
          }
        }
        className="thd-chat-root"
      >
        <>
          {/* 체팅창 영역 */}
          <Box
            id="chat-box"
            component="div"
            sx={{
              width: "100%",
              height: "100%",
              position: "relative",
            }}
            className={visibleStartIndex > 0 ? "thd-gradient-box" : ""}
          >
            <Box sx={{ height: "100%", width: "100%" }}>
              <AutoSizer style={{ height: "100%", width: "100%" }}>
                {({ height, width }) => (
                  <VariableSizeList
                    height={height}
                    width={width}
                    itemSize={getRowHeight}
                    itemCount={messages.length}
                    overscanCount={5}
                    className="thd-chat-list"
                    innerRef={chatInnerRef}
                    ref={listRef}
                    onItemsRendered={evtItemRendered}
                  >
                    {RenderRow}
                  </VariableSizeList>
                )}
              </AutoSizer>
            </Box>
            {/* 체팅창 스크롤 위로갈때 스크롤 아래로 이동하는 버튼 */}
            <Box className="btn-new-chat-hd" sx={btnNewMsgBoxStyle}>
              <Button
                sx={{ backgroundColor: "#ff376d", "&:hover": { backgroundColor: "#ff376d" }, borderRadius: "20px" }}
                variant="contained"
                size="small"
                onClick={scrollToBottom}
                endIcon={<ArrowCircleDownIcon />}
              >
                <span>최신 채팅으로 이동</span>
              </Button>
            </Box>
          </Box>
        </>
      </Box>
    </>
  );
};

export default forwardRef(ChatVerVod);
